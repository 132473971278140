import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { appInitFailed,setAppName } from '@laasy/common-layout'
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

setAppName(environment.app)
//loggly key
sessionStorage.setItem("_log",'8c22b8d0-1ce7-4536-bd0a-d4ad888e8d27')

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => {
  appInitFailed(err)
});
