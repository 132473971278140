import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { LaasyCookieService, urlProtocol } from '@laasy/common-layout'

@Injectable()
export class AuthGuard  {

  constructor(private laasyCookieService: LaasyCookieService,private readonly authenticationService: AuthenticationService,private readonly router: Router) { }

  async canActivate(): Promise<boolean> {
    return  await this.isAuthenticated()
  }

  async canActivateChild(): Promise<boolean> {
    return  await this.isAuthenticated()
  }

  private async isAuthenticated(): Promise<boolean>{
    const currentSession = await this.authenticationService.GetCurrentSession();

    if(currentSession != null)
    {
      this.laasyCookieService.setItem('id_token', currentSession.getIdToken().getJwtToken())
      return true;
    }

    window.location.href = urlProtocol(localStorage.getItem("HomeAppURL")||environment.redirectHomeBase)
    return false;
  }
}
