// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  app:'dev-travel',
  isCookieSecure: true,
  production: false,
  apiUri: "https://9oma2mjz24.execute-api.us-east-1.amazonaws.com/dev",
  s3AssetUrl : "https://dev-laasy-web-app-bucket.s3.amazonaws.com",
  mapbox: {
    accessToken: 'pk.eyJ1IjoibGFhc3lkZXYiLCJhIjoiY2xpdjMyYzIwMDN6dzNucXN1azhvd2FidSJ9.9lQD1cl10FRgGIH6ejHyXw',
    url:'https://api.mapbox.com/geocoding/v5/mapbox.places/'
  },
  accessDevelopmentUrl : "https://booking-stage.accessdevelopment.com/scripts/integration.js",
  whoAmI: "",
  cognitoUserPoolId: '',
  cognitoAppClientId: '',
  aws_cognito_social_providers:[],
  aws_domain:"",
  aws_redirectSignIn:"",
  aws_redirectSignOut:"",
  redirectHomeBase:"",
};

/*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
