/* eslint-disable max-len */
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-access-travel',
  templateUrl: './travel.component.html',
  styleUrls: ['./travel.component.scss'],
  standalone: true
})

export class AccessTravelComponent {

  title = 'LaaSyTravelMobile';
  src: string = '';
  code: string = '';

  constructor(
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      // Added testing cvt token need to remove while everything is setup
      this.src = this.generateUrl();
      this.loadScript(this.src);
    });
  }

  loadScript(url: string): void {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    document.getElementById('iframe-travel')?.appendChild(node);
  }

  generateParams(): any {
    let params: any;
    this.activatedRoute.queryParams.subscribe((p: any) => {
      params = p;
    });
    return params;
  }

  generateUrl(): string {
    const params = this.generateParams();
    let src: string;
    this.code = params.cvtcode || params.cvtCode;
    src =
      this.src = environment.accessDevelopmentUrl + `?cvt=${this.code}`;
    const view: any = params.view;
    switch (view) {
      case 'hotels': // hotels
        src =
          src +
          `&view=${view}&destination=${params.destination}&lat=${params.lat}&lon=${params.lon}&check_in=${params.check_in}&check_out=${params.check_out}&rooms=${params.rooms}&adults=${params.adults}`;
        if (params.children >= 1) {
          // if there are childrens, add there age to the url
          src =
            src +
            `&children=${params.children}&child_ages=${params.child_ages}`;
        }
        break;
      case 'cars': // cars
        src =
          src +
          `&view=${view}&destination=${params.destination}&pickup_date_time=${params.pickup_date_time}&return_date_time=${params.return_date_time}`;
        if (params.return_destination) {
          // if there is a return destination, add it to the url
          src = src + `&return_destination=${params.return_destination}`;
        }
        break;
      case 'activities': // activities
        src =
          src +
          `&view=${view}&destination=${params.destination}&lat=${params.lat}&lon=${params.lon}&startDate=${params.startDate}&endDate=${params.endDate}`;
        break;
      // for direct link to theme parks
      case 'wdw':
        src = src + `&view=${view}`;
        break;
      case 'dl':
        src = src + `&view=${view}`;
        break;
      case 'uh':
        src = src + `&view=${view}`;
        break;
      case 'sw':
        src = src + `&view=${view}`;
        break;
      case 'uo':
        src = src + `&view=${view}`;
        break;
      case 'flights':
        src = src + `&start_tab=flights`;
        break;

      default:
        src = src + `&view=hotels`;
        break;
    }
    return src;
  }
}
