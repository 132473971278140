import { Injectable } from '@angular/core';

import { Observable} from 'rxjs';
import { UserContextService } from 'src/app/shared/services/user-context.service';

@Injectable({
  providedIn: 'root'
})
export class ContextResolver  {

  constructor(private readonly userContextService: UserContextService){}

  resolve(): Observable<any> {
    return this.userContextService.loadUserContext();
  }
}
