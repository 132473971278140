import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ApplicationContextService } from '../../shared/services/application-context.service';



@Injectable({
  providedIn: 'root'
})
export class ApplicationContextResolver {

  constructor(private readonly applicationContextService: ApplicationContextService) { }

  async resolve(): Promise<Observable<any>> {
    return this.applicationContextService.loadApplicationContext();
  }
}
