import { Component, OnDestroy, OnInit, HostListener, ViewChild, AfterViewChecked, ElementRef } from '@angular/core';
import { filter } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { TenantService } from './shared/services/tenant/tenant.service';
import { Location } from '@angular/common';
import {Title} from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { LaasyCookieService, getLastUserKey, getUserDataKey, updateCSS, analytics_capture_toast, analytics_onClick, analytics_init, analytics_getUserDetails } from '@laasy/common-layout';



declare const analytics: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent  implements OnInit, OnDestroy,AfterViewChecked {
  title = 'LaaSy';
  layoutComponent:string = 'app-layout';
  favIcon:any
  isUserIdentified = false;
  previousUrl: string = '';
  currentUrl: string = '';
  userName:string='';
  email:string='';
  @ViewChild('toast', { read: ElementRef, static: false }) toastElementRef: ElementRef | undefined;
  private observerInitialized = false;

  constructor(private cookieService: LaasyCookieService,private readonly _router: Router,private readonly location: Location,private readonly tenantService:TenantService,private readonly titleService: Title) {}


  async ngOnInit(): Promise<void> {
    // Do not touch this if condition block unless very necessary
    // This if condition caters Access App (iframe) for LaaSy Mobile App
    if (location.pathname.includes("accessTravel") || location.pathname.includes("accesstravel"))
      this.layoutComponent = "app-iframe";
    else{
      this._router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe({
          next: () => {
            let layout = 'app-layout';
            if(location.pathname.includes('auth-redirect')){
              layout = 'default';
            }
            this.layoutComponent = layout;
          }
        })
    }

    this.analytics_initialize();
    this.tenant_config();
  }

  tenant_config() : void{
    this.favIcon=document.querySelector('#appIcon') || null;
    this.tenantService.getTenantAppConfig().subscribe((res:any) => {
        this.tenantService.envUpdated()
    })
  }

  @HostListener('document:click', ['$event'])
  analytics_onDocumentClick(event: MouseEvent) : void {
    analytics_onClick(analytics,this.currentUrl,event)
  }

  analytics_initialize() : void{
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        const lastUserKey = getLastUserKey(environment.cognitoAppClientId);
        const userDataKey = getUserDataKey(environment.cognitoAppClientId, this.cookieService.get(lastUserKey));
        const userData = this.cookieService.get(userDataKey);
        [this.userName, this.email] = analytics_getUserDetails(userData);
        this.isUserIdentified = analytics_init(analytics,this.userName, this.email,this.previousUrl,this.currentUrl,this.isUserIdentified)
      });
  }

  ngAfterViewChecked(): void {
    this.observerInitialized=analytics_capture_toast(analytics,this.observerInitialized,this.toastElementRef);
  }

  ngOnDestroy() : void{
    analytics.reset();
  }

}
