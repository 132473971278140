import { Injectable } from '@angular/core';
import { TenantContext } from '../../models/tenant/tenant-context';
@Injectable({
  providedIn: 'root',
})
export class TenantContextService {
  tenantContext!: TenantContext;


  checkPermission(attribute: string): string | boolean {
    const attr = attribute as keyof TenantContext;
    if (!this.tenantContext || this.tenantContext.tenantId) {
      return this.tenantContext[attr];
    }
    return this.tenantContext[attr] ?? false;
  }
}
