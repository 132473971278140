import { Injectable } from '@angular/core';
import { LoaderService } from '@laasy/common-layout';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AuthIntegrationService } from 'src/app/shared/services/authentication/auth-integration.service';
import { UserContextService } from 'src/app/shared/services/user-context.service';
import { delay, getUserPayload } from 'src/app/shared/utilities/utils';
import { environment } from 'src/environments/environment';
import { getLastUserKey, getUserDataKey,LaasyCookieService } from '@laasy/common-layout';
@Injectable({
  providedIn: 'root'
})
export class AccessRegistrationResolver {

  constructor(private laasyCookieService: LaasyCookieService, private readonly authService: AuthIntegrationService, private userService: UserContextService, private loader: LoaderService) { }

  async resolve(): Promise<Observable<number>> {

    const isReg = this.laasyCookieService.getItem('#UserAccessReg')||''
    this.loader.requestCount += 1
    this.loader.show()
    if (isReg=='') {
      const lastUserKey = getLastUserKey(environment.cognitoAppClientId)
      const lastUserVal = this.laasyCookieService.get(lastUserKey)
      const userDataKey = getUserDataKey(environment.cognitoAppClientId, lastUserVal)
      const userData = this.laasyCookieService.get(userDataKey)
      try {
        const verifyUserReg = await this.userService.verifyAccessUserRegistration().toPromise()
        if (verifyUserReg?.data?.userExist) {
          //user already registered
          this.laasyCookieService.setItem('#UserAccessReg', '1')
        } else {
          //register user
          const payload = getUserPayload(userData)
          const registerUser = await this.authService.addSignupUser(payload, 'Access').toPromise()
          if (registerUser?.isSuccess) {
            this.laasyCookieService.setItem('#UserAccessReg', '1')
            //delay to avoid cvt auth error when 1st register
            await delay(3000)
          }
        }
      } catch (error) {
        this.loader.requestCount -= 1
        this.loader.hide()
        return of(0)
      }
    }
    this.loader.requestCount -= 1
    this.loader.hide()
    return of(1)
  }
}
