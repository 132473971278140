import { HttpContextToken } from "@angular/common/http";

export class AppConstants {
  static themeParks = [
    {
      name: 'Walt Disney World',
      view: 'wdw',
      image: '/assets/images/walt-disney-world.jpg',
      state: 'Orlando, FL',
    },
    {
      name: 'Disneyland',
      view: 'dl',
      state: 'Anaheim, CA',
      image: '/assets/images/disneyland.png',
    },
    {
      name: 'SeaWorld Orlando',
      view: 'sw',
      state: 'Orlando, FL',
      image: '/assets/images/seaworld.png',
    },
    {
      name: 'Universal Studio Orlando',
      view: 'uo',
      state: 'Orlando, FL',
      image: '/assets/images/universal-orlando.png',
    },
    {
      name: 'Universal Studios Hollywood',
      view: 'uh',
      state: 'Los Angeles, CA',
      image: '/assets/images/universal-hollywood.png',
    },
  ];

  static dealsConstants = class {
    public static filters = {
      categories: [
        {
          name: 'Dining',
          isActive: false,
          type: 'category',
          value: '1',
        },
        {
          name: 'Entertainment',
          isActive: false,
          type: 'category',
          value: '2',
        },
        {
          name: 'Shopping',
          isActive: false,
          type: 'category',
          value: '3',
        },
        {
          name: 'Wellness',
          isActive: false,
          type: 'category',
          value: '4',
        },
      ],
      offerType: [
        {
          name: 'New',
          isActive: false,
          type: 'offerType',
          value: 'new',
        },
        {
          name: 'Expiring Soon',
          isActive: false,
          type: 'offerType',
          value: 'expiring_soon',
        },
        {
          name: 'Unlimited',
          isActive: false,
          type: 'offerType',
          value: 'unlimited',
        },
        {
          name: 'Limited',
          isActive: false,
          type: 'offerType',
          value: 'limited',
        },
        {
          name: 'Promocode',
          isActive: false,
          type: 'offerType',
          value: 'promo_code',
        },
        {
          name: 'Buy 1 get 1 free',
          isActive: false,
          type: 'offerType',
          value: 'bogo',
        },
      ],
      redemptionMethod: [
        {
          name: 'In store',
          isActive: false,
          type: 'redemptionMethod',
          value: 'instore',
        },
        {
          name: 'Online',
          isActive: false,
          type: 'redemptionMethod',
          value: 'link',
        },
        {
          name: 'Call',
          isActive: false,
          type: 'redemptionMethod',
          value: 'call',
        },
      ],
      distance: [
        {
          name: 'Up to 1 mi',
          isActive: false,
          type: 'distance',
          value: '1mi',
        },
        {
          name: 'Up to 5 mi',
          isActive: false,
          type: 'distance',
          value: '5mi',
        },
        {
          name: 'Up to 10 mi',
          isActive: false,
          type: 'distance',
          value: '10mi',
        },
        {
          name: 'Up to 15 mi',
          isActive: true,
          type: 'distance',
          value: '15mi',
        },
        {
          name: 'Up to 20 mi',
          isActive: false,
          type: 'distance',
          value: '20mi',
        },
        {
          name: 'Up to 25 mi',
          isActive: false,
          type: 'distance',
          value: '25mi',
        },
      ],
    };

    public static sorting = [
      {
        label: 'Best value',
        value: 'offer_value',
        isSelected: true,
        sortDirection: 'desc',
      },
      {
        label: 'Newest',
        value: 'start_date',
        isSelected: false,
        sortDirection: 'desc',
      },
      {
        label: 'Closest',
        value: 'distance',
        isSelected: false,
        sortDirection: 'asc',
      },
    ];

    public static dealsByBrandSorting = [
      {
        label: 'A - Z',
        value: 'name',
        isSelected: true,
        sortDirection: 'asc',
      },
      {
        label: 'Z - A',
        value: 'name',
        isSelected: false,
        sortDirection: 'desc',
      },
      {
        label: 'Number of Locations',
        value: 'location_count',
        isSelected: false,
        sortDirection: 'desc',
      },
    ];
  };

  public static homePageQuickAccessData = [
    {
      title: 'Earn Cashback',
      img: 'cashback.svg',
      content: 'Delivered right to your card on select travel purchases',
      link: '/cashback',
      buttonText:'Learn More'
    },
    {
      title: 'Dining Out',
      img: 'burger.svg',
      content: 'On a budget? We’ve got you covered.',
      link: '/coupons/dining',
      buttonText:'Explore'
    },
  ]

  public static featuredTitle = {
    featuredBannerTitle:'Featured Banner',
    topDestinationsTitle:'Top Destinations',
    featuredDealsTitle: 'Featured Coupons',
    featuredAttractionsTitle: 'Featured Attractions'
  }

  public static subscriptionIdentifiers = {
    signInComponent: 'signIn',
    signUpComponent: 'signUp',
    signupMiddlewareComponent: 'signUpMiddleware',
    // Travel component in Access module
    accessTravelComponent: 'accessTravel',
    authorizedHeaderComponent: 'authorizedHeader',
    homeComponent: 'home',
    footerComponent: 'footer',
    faqComponent: 'faq',
    faqContentComponent: 'faqContent',
    // Travel component in Travel module
    travelComponent: 'travel',
    carRentalComponent: 'carRental',
    travelCategoryTemplateComponent: 'travelCategory',
    AllDealsComponent: 'allCoupons',
    brandDetailsComponent: 'brandDetails',
    cruisesComponent: 'cruises',
    dealsByBrandComponent: 'couponsByBrand',
    dealsLandingPageComponent: 'couponsLandingPage',
    diningComponent: 'dining',
    entertainmentComponent: 'entertainment',
    featuredDealsComponent: 'featuredCoupons',
    savedDealsComponent: 'savedCoupons',
    shoppingComponent: 'shopping',
    wellnessComponent: 'wellness',
    profileComponent: 'profile',
    accountComponent: 'account',
    cookiesPolicyComponent: 'cookiesPolicy',
    licensesComponent: 'licenses',
    privacyPolicyComponent: 'privacyPolicy',
    termsComponent: 'terms',
    thirdPartyPolicyComponent: 'thirdPartyPolicy',
    supportComponent: 'support',
    yourDealsComponent: 'yourCoupons',
    yourTripsComponent: 'yourTrips',
    appLoaderComponent: 'appLoader',
    breadcrumbComponent: 'breadcrumb',
    filtersComponent: 'filters',
    offersCardComponent: 'offersCard',
    offersCardModalComponent: 'offersCardModal',
    searchBannerComponent: 'searchBanner',
    imageBannerService: 'imageBannerService',
    geoLocationService: 'geoLocationService',
    searchDealsService: 'searchDealsService',
  };

  public static termsOfUse = String.raw`<p>Welcome to LaaSy! LaaSy is a next-generation loyalty platform that
  enables organizations to <b>inspire and reward loyalty</b> with a white-label solution for mobile and web that adapts
   to your organization’s mission, brand, and unique community of users. The LaaSy website, the LaaSy loyalty
    portal and all services provided by LaaSy through the website and portal  are collectively referred to
    herein as the "Service".</br>The Service is a copyrighted work belonging to LaaSy, Inc.
    ("LaaSy", "us", "our", and "we"). Certain features of
    the Service may be subject to additional guidelines, terms, or rules, which will be posted on the Service
     in connection with such features. All such additional terms, guidelines, and rules are incorporated by
      reference into these Terms.</br>THESE TERMS OF USE, OUR TERMS OF SALE AND OUR PRIVACY POLICY (THESE "TERMS")
      SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SERVICE.</br> THESE TERMS REQUIRE
      THE USE OF ARBITRATION (SECTION 14.2) ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS
       OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.</p>`;
}

export class CognitoResponseCode {
  static UserNotConfirmedException = 'UserNotConfirmedException';
  static NotAuthorizedException = 'NotAuthorizedException';
  static UsernameExistsException = 'UsernameExistsException';
  static CodeDeliveryFailureException = 'CodeDeliveryFailureException';
  static InvalidPasswordException = 'InvalidPasswordException';
  static CodeMismatchException = 'CodeMismatchException';
  static Success = 'SUCCESS';
}

export const travelTabs=[
  {id:'hotel',title:'Hotels',icon:'../../../../assets/icons/Bed.svg',url:'/travel/hotels',isVisible:true,color:"#6F6F6F"},
  {id:'carrentals',title:'Car rentals',icon:'../../../../assets/icons/Drive.svg',url:'/travel/carrentals',isVisible:true,color:"#6F6F6F"},
  {id:'themepark',title:'Theme parks',icon:'../../../../assets/icons/Themepark.svg',url:'/travel/themepark',isVisible:true,color:"#6F6F6F"},
  {id:'thingstodo',title:'Things to do',icon:'../../../../assets/icons/Thingstodo.svg',url:'/travel/thingstodo',isVisible:true,color:"#6F6F6F"},
  // {id:'flight',title:'Flights',icon:'../../../../assets/icons/Plane.svg',url:'/travel/flights',isVisible:true,color:"#6F6F6F"}
]

export const HIDE_LOADER = new HttpContextToken(() => true);

export const travelType = {
  hotel: 'hotel',
  hotels: 'hotels',
  thingsToDo: 'thingsToDo'
}