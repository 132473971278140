import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TenantService } from '../shared/services/tenant/tenant.service';
import { CommonLayoutModule } from '@laasy/common-layout';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  imports: [CommonLayoutModule, RouterOutlet]
})
export class LayoutComponent implements OnInit {

  ApiConfig={};
  productName = 'travel';
  internalRoute=[
    { title: 'Travel', url: '/travel' },
    { title: 'Coupons', url: '/coupons' },
  ]

  constructor(private readonly tenantService: TenantService, private readonly router: Router) {
    this.updateHeaderFooterConfig()
  }

  async ngOnInit(): Promise<void> {
    this.tenantService.isEnvUpdated$.subscribe(_=>{
      this.updateHeaderFooterConfig()
    })
    this.router.events.subscribe((event:any) => {
      if (event instanceof NavigationEnd) {
        const { url } = event;
        if(url.includes('travel')) this.productName = 'travel';
        else if(url.includes('coupons')) this.productName = 'coupons'
      }
    });
  }

  updateHeaderFooterConfig(): void{
    this.ApiConfig={
      apiBase:environment.apiUri,
      whoAmI:environment.whoAmI,
      cognitoAppClientId:environment.cognitoAppClientId,
      redirectHomeBase: environment.redirectHomeBase,
      internalRoutes:this.internalRoute,
    }
  }


  onActivate(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
