import { CommonModule } from "@angular/common";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonLayoutModule } from "@laasy/common-layout";
import { ConfirmationService, MessageService } from "primeng/api";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ToastModule } from "primeng/toast";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { AuthorizeGuard } from "./core/guards/authorize.guard";
import { HttpExchangeInterceptor } from "./core/interceptors/http.Interceptor";
import { ContextResolver } from "./core/resolvers/context.resolver";
import { TenantContextResolver } from "./core/resolvers/tenant-context.resolver";
import { IframeLayoutComponent } from "./iframe-layout/iframe-layout.component";
import { AccessTravelComponent } from "./modules/access/travel/travel.component";
import { myAppInitializer } from "./shared/services/app-initializer";
import { RouterModule } from "@angular/router";
import { ROUTES } from "./app-routing.module";
import { LayoutComponent } from "./layout/layout.component";
import { NotificationService } from "./shared/services/common/notification.service";
import { TenantService } from "./shared/services/tenant/tenant.service";

export function fetchDataFactory(apiService: TenantService) {
  return ()=>apiService.getTenantAppConfigPromise()
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastModule,
    CommonModule,
    ProgressSpinnerModule,
    FormsModule,
    CommonLayoutModule,
    IframeLayoutComponent,
    LayoutComponent,
    AccessTravelComponent,
    RouterModule.forRoot(ROUTES)
  ],
  exports:[ ],
  providers: [
    TenantContextResolver,
    ContextResolver,
    ConfirmationService,
    MessageService,
    NotificationService,
    HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpExchangeInterceptor,
      multi: true
    },
    AuthGuard,
    AuthorizeGuard,
    {
      provide : APP_INITIALIZER,
      useFactory : myAppInitializer,
      multi : true,
      deps : []
    },
    { provide: APP_INITIALIZER, useFactory: fetchDataFactory, deps: [TenantService], multi: true },

  ],
  schemas: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
