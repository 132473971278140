import { Routes } from '@angular/router';
import { AccessTravelComponent } from './modules/access/travel/travel.component';
import { PageNotFoundComponent, ErrorPageComponent } from '@laasy/common-layout';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthorizeGuard } from './core/guards/authorize.guard';
import { ApplicationContextResolver } from './core/resolvers/application-context.resolver';
import { EmptyComponent } from './modules/pages/empty/empty.component';
import { AccessRegistrationResolver } from './core/resolvers/access-registration.resolver';
import { TenantContextResolver } from './core/resolvers/tenant-context.resolver';

export const ROUTES: Routes = [

  {
    path: 'travel',
    loadChildren: () => import('./../app/modules/travel/travel-routing.module').then((m) => m.TRAVEL_ROUTES),
    canActivate: [AuthGuard, AuthorizeGuard],
    resolve: { TenantContextResolver,ApplicationContextResolver },
  },
  {
    path: 'coupons',
    loadChildren: () => import('./modules/deals/deals-routing.module').then((m) => m.DEALS_ROUTES),
    canActivate: [AuthGuard, AuthorizeGuard],
    resolve: { TenantContextResolver,ApplicationContextResolver,AccessRegistrationResolver },
  },
  { path: 'accessTravel', component: AccessTravelComponent, pathMatch: 'full' },
  { path: 'auth-redirect', component: EmptyComponent },
  { path: 'support/error', component: ErrorPageComponent },
  { path: '', redirectTo: 'travel', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent, data: { showFAQ: false }, canActivate: [AuthGuard] },
  { path: '', redirectTo: 'app', pathMatch: 'full' },
];
