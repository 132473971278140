import { Injectable } from '@angular/core';

import { Observable, tap, of, Subject } from 'rxjs';
import { TenantService } from '../../shared/services/tenant/tenant.service';
import { TenantContext } from 'src/app/shared/models/tenant/tenant-context';
import { TenantContextService } from 'src/app/shared/services/tenant/tenant-context.service';

@Injectable({
  providedIn: 'root',
})
export class TenantContextResolver  {
  tenantContext!: TenantContext;
  private readonly tenantContextSubject = new Subject<TenantContext>();
  
  constructor(private readonly tenantService: TenantService,
    private readonly tenantContextService:TenantContextService ) {}

  resolve(): Observable<any> {
    if (!this.tenantContext) {
      return this.tenantService.getTenantContext().pipe(
        tap(
          (res) => {
            this.tenantContext = res.data;
            this.tenantContextService.tenantContext = res.data;
            this.tenantContextSubject.next(res.data);
          },
          (error) => {
            console.log(error);
          }
        )
      );
    } else {
      return of({ data: this.tenantContext });
    }
  }

  getTenantContext(): Observable<TenantContext> {
    return this.tenantContextSubject.asObservable();
  }
}
