import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LaasyCookieService, LoaderService, urlProtocol } from '@laasy/common-layout';
import { environment } from 'src/environments/environment';
import { HIDE_LOADER } from 'src/app/shared/utilities/constants';

@Injectable({
  providedIn: 'root'
})
export class HttpExchangeInterceptor implements HttpInterceptor {
  uploadUrls = [];
  ignoreAuthUrls = [
    '/auth/signup'
  ];

  constructor(private readonly loaderService: LoaderService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!request?.context?.has(HIDE_LOADER)){
      this.loaderService.requestCount += 1;
      this.loaderService.show();
    }

    return next.handle(request).pipe(tap({
      next: (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      },
      error: (res: any) => this.handleHttpError(res)
    })).pipe(finalize(() => {
      this.loaderService.requestCount -= 1;
      this.loaderService.hide();
    }));
  }


  handleHttpError(res: any): any {
    if (res instanceof HttpErrorResponse) {
      if (res.status === 0 && res.statusText === 'Unknown Error') {
        return of(res);
      } else if (res.status === 401 || res.status === 403) {
        // this.notificationService.showWarn("unauthorized","unauthorized request");
        setTimeout(()=>window.location.href = urlProtocol(localStorage.getItem("HomeAppURL")||environment.redirectHomeBase))
      } else if (res.status === 500){
        // this.notificationService.showWarn("Server Error","Sorry, something went wrong on our end. Please try again later.");
      } else {
        // some error message
        return of(res);
      }
    }
    return null;
  }

}
