import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { UserContextService } from 'src/app/shared/services/user-context.service';
import { TenantContextResolver } from 'src/app/core/resolvers/tenant-context.resolver';

@Injectable({
  providedIn: 'root',
})
export class ApplicationContextService {
  constructor(
    private readonly userContextService: UserContextService,
    private readonly tenantContextResolver :TenantContextResolver
  ) {}

  loadApplicationContext(): Observable<any> {
    if (this.tenantContextResolver.tenantContext) {
      return this.userContextService.loadUserContext();
    }
    return this.tenantContextResolver.resolve().pipe(
      switchMap((res)=>{
        return this.userContextService.loadUserContext();
      })
    );
  }
}
