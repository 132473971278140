/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { LoaderService } from '@laasy/common-layout';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  constructor(private readonly loaderService: LoaderService) { }

  async GetCurrentSession(): Promise<any>
  {
    try
    {
      this.loaderService.show()
      const response = await Auth.currentSession();
      this.loaderService.hide()
      return response;
    } catch (error: any) {
      console.log(error)
      this.loaderService.hide()
      return null;
    }
  }
}
