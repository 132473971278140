import { Component } from '@angular/core';

@Component({
  selector: 'app-empty',
  standalone: true,
  template: '',
})
export class EmptyComponent {
  constructor(){
    setTimeout(() => {
      window.location.href = '/'
    }, 5000);
  }
}
