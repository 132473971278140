<div [ngSwitch]="layoutComponent">
    <app-layout *ngSwitchCase="'app-layout'">
        <router-outlet></router-outlet>
    </app-layout>

    <app-iframe-layout *ngSwitchCase="'app-iframe'">
        <router-outlet></router-outlet>
    </app-iframe-layout>

    <div *ngSwitchCase="'auth'">
      <router-outlet></router-outlet>
    </div>

    <div *ngSwitchDefault>
        <router-outlet></router-outlet>
    </div>
</div>
<app-loader></app-loader>
<p-toast #toast position="top-right"></p-toast>
<l-auth-redirect></l-auth-redirect>
