import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { NotificationService } from '../../shared/services/common/notification.service';
import { TenantService } from 'src/app/shared/services/tenant/tenant.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeGuard  {
  constructor(
    private readonly tenantService: TenantService,
    private readonly router: Router,
    private readonly notificationService: NotificationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.tenantService.getTenantContext().pipe(tap((data:any)=>{
      if (data.data) {
        switch (state.url.startsWith('')) {
          case state.url.startsWith('/travel') &&
              !data.data?.travel:
          case state.url.startsWith('/coupons/dining') &&
              !data.data?.dining:
          case state.url.startsWith('/coupons/entertainment') &&
              !data.data?.entertainment:
          case state.url.startsWith('/coupons/shopping') &&
              !data.data?.shopping:
          case state.url.startsWith('/coupons/wellness') &&
              !data.data?.wellness:
          case state.url.startsWith('/coupons') &&
              !data.data?.dining &&
              !data.data?.shopping &&
              !data.data?.entertainment:
            this.UnAuthorizedRequestHandler();
            break;
        }
      }
      else{
        this.UnAuthorizedRequestHandler();
        return false;
      }
      return true;
    }))

  }

  UnAuthorizedRequestHandler(): boolean {
    this.notificationService.showWarn('Unauthorized', 'Unauthorized request');
    this.router.navigateByUrl('/');
    return false;
  }
}
