import { AddressList } from '../models/addressList';
import { Travel } from '../models/travel';
import { UserViewModel } from '../models/user';
import { ImageBannerService } from '../services/image-banner.service';
import { getCognitoUserdata } from '@laasy/common-layout'

export class Utils {
  public static getLocalStorageValue(key: string): string | null {
    return localStorage.getItem(key);
  }

  public static setLocalStorageValue(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public static removeTokenFromLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  public static formatDate(date: Date): string {
    const yyyy = date.getFullYear();
    let mm: number | string = date.getMonth() + 1;
    let dd: number | string = date.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${yyyy}-${mm}-${dd}`;
  }

  public static importFile(file: File, url: string): void {
    const formData: FormData = new FormData();
    formData.append('fileKey', file, file.name);
    const request = new XMLHttpRequest();
    request.open('POST', url);
    request.send(formData);
  }

  public static formatPhoneNumber(input: string): string {
    const cleaned = input.replace(/\D/g, '');
    if (cleaned.length === 10) {
      return `(${cleaned.substring(0, 3)}) ${cleaned.substring(
        3,
        6
      )}-${cleaned.substring(6)}`;
    } else if (
      cleaned.length === 14 &&
      cleaned.match(/^\(\d{3}\) \d{3}-\d{4}$/) != null
    ) {
      return input;
    }
    return input;
  }

  public static getKeyFromEnumValue(enumName: any, value: any): string {
    const index = Object.values(enumName).indexOf(value);
    return Object.keys(enumName)[index];
  }

  public static getKeyFromObjectValue(objectName: any, value: any): string {
    const key = Object.keys(objectName).find((x) => objectName[x] === value);
    return key === undefined ? '' : key;
  }

  private static getTravelCategoryKey(category: string): string {
    const storageKeyPrefix = 'travel_';
    return `${storageKeyPrefix}${category}`;
  }

  public static saveRecentTravelCategory(category: string, value: any): void {
    const storageKey = this.getTravelCategoryKey(category);
    let recentTravelCategory = this.getRecentTravelCategory(category);

    if (category === 'recentLocations') {
      // Remove the location if it already exists
      recentTravelCategory = recentTravelCategory.filter((loc: AddressList) => {
        return (
          loc.coordinates.lat !== value.coordinates.lat ||
          loc.coordinates.lon !== value.coordinates.lon
        );
      });
    } else if (category === 'recentAirports') {
      // Remove the airport if it already exists
      recentTravelCategory = recentTravelCategory.filter((x: Travel) => {
        return (
          x.coordinates.lat !== value.coordinates.lat ||
          x.coordinates.lon !== value.coordinates.lon
        );
      });
    }

    // Add the recent search at the beginning of the array
    recentTravelCategory.unshift(value);

    // Keep only the first 3 searches
    recentTravelCategory = recentTravelCategory.slice(0, 3);

    // Save the updated array to local storage
    localStorage.setItem(storageKey, JSON.stringify(recentTravelCategory));
  }

  public static getRecentTravelCategory(category: string): any[] {
    const storageKey = this.getTravelCategoryKey(category);
    const storedLocations = localStorage.getItem(storageKey);
    return storedLocations ? JSON.parse(storedLocations) : [];
  }

  public static getBackgroundImage(screenName: string, sectionName: string): string {
    if (ImageBannerService.imageBanners.length > 0) {
      const imageUrl =
        ImageBannerService.imageBanners.find(
          (x) =>
            x.screenName.toLowerCase() === screenName.toLowerCase() &&
            x.sectionName.toLowerCase() === sectionName.toLowerCase()
        )?.imageUrl ?? '';
      return imageUrl;
    }
    return '';
  }
}


export function getUserPayload(Data: any) {
  const userData = getCognitoUserdata(Data)
  const payload: UserViewModel = {
    userId: userData.sub || '',
    firstName: userData.given_name || '',
    lastName: userData.family_name || '',
    email: userData.email,
    displayName: '',
    accountType: '',
    timeStamp: new Date(),
    phoneNumber: '',
  };
  return payload
}

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
