import { Injectable, OnDestroy } from '@angular/core';
import { AuthorizedApiService } from '@laasy/common-layout';
import { Observable } from 'rxjs';
import { ImageBanner } from '../models/imageBanner';
import { AppConstants } from '../utilities/constants';
import { SubscriptionService } from './subscription.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImageBannerService implements OnDestroy {
  static imageBanners: ImageBanner[] = [];
  imageBanners: ImageBanner[] = [];
  subscriptionIdentifier: string =
    AppConstants.subscriptionIdentifiers.imageBannerService;

  constructor(
    private readonly apiService: AuthorizedApiService,
    private readonly subscriptionService: SubscriptionService
  ) {}

  getImageBannersData(): Observable<any> {
    return this.apiService.getAndCache<any>('/tenant/api/assets/backgrounds',environment.apiUri,environment.whoAmI);
  }

  getImageBannerByScreenNameAndSectionName(
    screenName: string,
    sectionName: string
  ): string {
    if (this.imageBanners.length > 0) {
      const imageUrl =
        this.imageBanners.find(
          (x) =>
            x.screenName.toLowerCase() === screenName.toLowerCase() &&
            x.sectionName.toLowerCase() === sectionName.toLowerCase()
        )?.imageUrl ?? '';
      return imageUrl;
    }
    return '';
  }

  getImageBanners(): void {
    if (this.imageBanners.length === 0) {
      const subscription = this.getImageBannersData().subscribe((res) => {
        ImageBannerService.imageBanners = res.data;
        this.imageBanners= res.data;
      });
      this.subscriptionService.add(this.subscriptionIdentifier, subscription);
    }
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll(this.subscriptionIdentifier);
  }
}
