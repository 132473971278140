<l-header
    [apiConfig]="ApiConfig"
    [productName]="productName"
    [internalApi]="internalRoute"
></l-header>
<div class="canvas">
    <router-outlet (activate)="onActivate()">

    </router-outlet>
</div>
<l-footer [apiConfig]="ApiConfig"></l-footer>
