import { Injectable } from '@angular/core';
import { AuthorizedApiService } from '@laasy/common-layout';
import { UserViewModel } from '../../models/user';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../models/apiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthIntegrationService {

  constructor(private readonly apiService:AuthorizedApiService) { }

  addSignupUser(userRequest: UserViewModel,vendor: string): Observable<ApiResponse>{
    return this.apiService.post<ApiResponse>(`user/api/usermanagement/signup?vendor=${vendor}`, userRequest,environment.apiUri,environment.whoAmI)
  }

  checkUserByEmail(email: string): Observable<ApiResponse>{
    const request = {
      email
    }
    return this.apiService.post<ApiResponse>(`user/api/usermanagement/verifyUserEmail`, request,environment.apiUri,environment.whoAmI);
  }

  checkUserByUserId(userId: string): Observable<ApiResponse>{
    return this.apiService.get<ApiResponse>(`user/api/usermanagement/verifyUserByUserId?userid=${userId}`,environment.apiUri,environment.whoAmI);
  }

  cancelUserMembership(userId: string): Observable<ApiResponse>{
    return this.apiService.delete<ApiResponse>(`user/api/account/cancelMembership`,environment.apiUri,environment.whoAmI);
  }
}
