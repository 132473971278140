import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { AccessToken, WhoAmI } from '../models/whoAmI';
import { environment } from 'src/environments/environment';
import { APIResponse } from '../models/deals/pagedOffers';
import { AuthorizedApiService } from '@laasy/common-layout';
import { ApiResponse } from '../models/apiResponse';

@Injectable({
  providedIn: "root"
})
export class UserContextService {
  whoAmI!: WhoAmI;
  private readonly whoAmISubject = new Subject<WhoAmI>();

  constructor(private readonly apiService: AuthorizedApiService) { }

  loadUserContext(): Observable<any> {
    return this.getUserContext().pipe(
      tap(res => {
        this.whoAmI = res.data;
        this.whoAmISubject.next(res.data);
      }));
  }

  getWhoAmI(): Observable<WhoAmI> {
    return this.whoAmISubject.asObservable();
  }

  getAccessToken(): Observable<any>{
    return this.apiService.getAndCacheWithLocal<APIResponse<AccessToken>>(`/user/api/vendorauth/vendorTokenGeneration?vendor=Access`,environment.apiUri,environment.whoAmI);
  }

  verifyAccessUserRegistration(): Observable<any>{
    return this.apiService.get<APIResponse<any>>(`user/api/vendorauth/verifyUserVendorRegistration?vendor=Access`,environment.apiUri,environment.whoAmI)
  }

  getUserContext(): Observable<ApiResponse> {
    return this.apiService.getAndCacheWithCookie<ApiResponse>('api/account/context',environment.apiUri,environment.whoAmI);
  }

}
